<template>
  <div
    :style="[
      section.styles.cssVariables.value,
      `--section-primary-color: ${section.data?.value?.primaryColor}`,
      `--el-color-primary: ${section.data?.value?.primaryColor}`,
      `--color-primary-500: ${colorPalette['builder-primary-500']}`,
      `--color-primary-500-15: ${colorPalette['builder-primary-500/15']}`,
      `--color-primary-600: ${colorPalette['builder-primary-600']}`,
      `--color-primary-700: ${colorPalette['builder-primary-700']}`,
    ]"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div
      class="max-w-[35rem] mx-auto border border-(border-muted) rounded-lg bg-white text-black"
    >
      <div
        v-if="isSubmitted || useBuilderStore?.().formBasicShowSubmitted"
        class="flex flex-col justify-center items-center py-44 px-8"
      >
        <Lottie
          :animation-data="checkLottie"
          :height="150"
          :width="150"
          :loop="false"
          class="lottie-check"
        />
        <template v-if="props.guest && props.guest?.status?.confirmation > 0">
          <h3 class="mb-1 text-center">
            {{ section.$t('xyz18') }}
          </h3>
        </template>
        <template v-else>
          <h3 class="mb-1 text-center">
            {{ props.data?.successBox?.[lang]?.title || section.$t('xyz19') }}
          </h3>
          <p class="m-0 text-[#48484A] text-center">
            {{ props.data?.successBox?.[lang]?.description || section.$t('xyz20') }}
          </p>
        </template>
      </div>
      <template v-else>
        <div
          v-if="!isBeforeEndDate"
          class="py-20"
        >
          <EmptyState
            :title="section.$t('xyz21')"
            :description="section.$t('xyz22')"
            class="h-full"
          />
        </div>
        <div
          v-else-if="props.stats?.guests >= props.event?.planMaxCapacity && !props.guest?._id"
          class="py-20"
        >
          <EmptyState
            :title="section.$t('xyz23')"
            :description="section.$t('xyz24')"
            class="h-full"
          />
        </div>
        <div v-else-if="!isRegistrationLimitValid">
          <EmptyState
            :title="section.$t('xyz25')"
            :description="section.$t('xyz26')"
            class="h-full"
          />
        </div>
        <Form
          v-else
          :model="form"
          :rules="rules"
          class="py-8 px-12"
          @submit="onSubmit"
        >
          <Badge
            v-if="props.guest?.status?.confirmation > 0"
            :text="section.$t('xyz12')"
            type="success"
            class="mb-4"
          />

          <Badge
            v-if="props.guest?.status?.confirmation === -1"
            :text="section.$t('xyz13')"
            type="critical"
            class="mb-4"
          />

          <InputBlock
            v-for="property in baseProperties"
            :key="property.id"
            :label="property[lang]?.name"
            :prop="`properties.${property.id}`"
            show-asterisk
          >
            <TextInput
              v-if="property.type === 'text'"
              v-model="form.properties[property.id]"
              :type="property.id === 'email' ? 'email' : 'text'"
              :disabled="!!props.guest?.properties?.[property.id]"
            />
          </InputBlock>

          <template v-if="isMounted">
            <!-- fixing select SSR bug -->
            <PageFormBasicInputBlock
              v-for="field in section.data?.value.fields"
              :key="field.propertyId"
              v-model:form="form"
              :field="field"
              :lang="lang"
              :prop="`properties.${field.propertyId}`"
              :stats="props.stats"
              :event="props.event"
              :section="section"
              :partners="form.partners"
            />
          </template>

          <div
            v-if="partnersMaxLimit !== 0"
            class="border-t border-gray-200 py-4"
          >
            <div>
              <InputBlock class="mb-0">
                <Checkbox
                  :model-value="form.partners >= 1"
                  :label="section.$t('xyz27')"
                  @update:model-value="(value) => {
                    form.partners = value ? 1 : 0;
                  }"
                />
              </InputBlock>

              <InputBlock
                v-if="form.partners >= 1 && partnersMaxLimit > 1"
                :label="section.$t('xyz28')"
              >
                <NumberInput
                  v-if="partnersMaxLimit > 1"
                  v-model="form.partners"
                  :min="1"
                  :max="partnersMaxLimit"
                />
              </InputBlock>
            </div>
          </div>

          <div
            v-if="section.data?.value?.consents?.length"
            class="border-t border-gray-200"
          >
            <div class="py-5">
              <InputBlock
                prop="givenConsents"
                class="mb-0"
              >
                <div class="w-full">
                  <CheckboxGroup
                    :model-value="form.givenConsents || []"
                    :options="(section.data?.value?.consents || []).map((consent) => ({
                      id: consent[lang]?.text,
                      text: `${striptags(consent[lang]?.text || '', ['a', 'em', 'strong'])} <span class='text-red-500'>*</span>`,
                      description: getConsentDescription(consent),
                    }))"
                    @update:model-value="(value) => _set(form, 'givenConsents', value)"
                  />
                </div>
              </InputBlock>
            </div>
          </div>

          <Button
            type="primary"
            size="large"
            class="w-full"
            native-type="submit"
            :loading="isSubmitting"
            :disabled="useRoute()?.path?.includes('/builder/') || !isMounted"
          >
            {{ props.data?.[lang]?.submitButtonText || (props.guest ? section.$t('xyz29') : section.$t('xyz864', {}, i18nOptions)) }}
          </Button>
        </Form>
      </template>
    </div>
  </div>
</template>

<script setup>
import checkLottie from '~/assets/json/lottie/check.json';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  guest: {
    type: Object,
    default: undefined,
  },
  event: {
    type: Object,
    default: undefined,
  },
  stats: {
    type: Object,
    default: () => ({}),
  },
  pageId: {
    type: String,
    default: undefined,
  },
  preview: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['refresh-stats']);

// Refs
const section = usePageSection(props);
const lang = inject('lang');
const isMounted = useMounted();

const form = ref({
  properties: {},
  givenConsents: [],
  partners: 0,
});
const isSubmitting = ref(false);
const isSubmitted = ref(false);
const currentInstance = getCurrentInstance();

// Computed
const i18nOptions = computed(() => ({
  locale: lang.value.replace('_', ''),
}));

const baseProperties = computed(() => ([
  {
    id: 'firstName',
    [lang.value]: {
      name: section.$t('xyz357'),
    },
    type: 'text',
  },
  {
    id: 'lastName',
    [lang.value]: {
      name: section.$t('xyz358'),
    },
    type: 'text',
  },
  {
    id: 'email',
    [lang.value]: {
      name: section.$t('xyz996'),
    },
    type: 'text',
  },
]));

const colorPalette = computed(() => useTokenColors(section.data?.value?.primaryColor).colorPalette?.value);
const rules = computed(() => {
  const fields = section.data?.value?.fields || [];
  const rules = {
    'properties.firstName': [
      { ...useFormRules().required, message: section.$t('xyz30') },
    ],
    'properties.lastName': [
      { ...useFormRules().required, message: section.$t('xyz30') },
    ],
    'properties.email': [
      { ...useFormRules().required, message: section.$t('xyz30') },
    ],
  };

  if (!props.guest?.properties?.email) {
    rules['properties.email'].push({ ...useFormRules().email, message: section.$t('xyz239') });
  }

  fields.forEach((field) => {
    if (field?.required) {
      const property = props.event?.guestlist?.properties?.find((property) => property.id === field.propertyId);
      if (property) {
        const hasAllOptionsCapacityReached = property.options?.every((option) => {
          const maxCapacity = field?.optionsSettings?.[option.id]?.maxCapacity;
          const stat = props.stats?.guestPropertyStats?.[`${field.propertyId}/${option.id}`];
          return Boolean(maxCapacity && stat >= maxCapacity);
        });

        if (!hasAllOptionsCapacityReached || property.type === 'text') {
          rules[`properties.${field.propertyId}`] = [
            { ...useFormRules().required, message: section.$t('xyz30') },
          ];
        }
      }
    }
  });

  if (section.data?.value?.consents?.length > 0) {
    rules.givenConsents = [
      useFormRules().required,
      {
        validator: (rule, consents, callback) => {
          const requiredConsents = section.data?.value?.consents?.map((consent) => consent[lang.value]?.text);
          const hasAllRequiredConsents = requiredConsents.every((requiredConsent) => consents.includes(requiredConsent));

          if (hasAllRequiredConsents || useRoute()?.path?.includes('/builder/')) {
            callback();
          } else {
            callback(new Error(section.$t('xyz31')));
          }
        },
      },
    ];
  }

  return rules;
});

const isRegistrationLimitValid = computed(() => {
  if (section.data.value?.registrationLimitType === 'none') {
    return true;
  }

  if (section.data.value?.registrationLimitType === 'date') {
    return useDayjs().isBefore(useDayjs(section.data.value?.registrationLimit));
  }
  if (section.data.value?.registrationLimitType === 'number') {
    return props.stats?.guestsConfirmed < section.data.value?.registrationLimit;
  }

  return true;
});
const partnersMaxLimit = computed(() => {
  if (section.data.value.partnersMode === 'allowed') {
    return section.data.value.partnersLimit;
  }

  if (section.data.value.partnersMode === 'condition') {
    const conditionLimits = (section.data.value.partnersLimit || [])
      .filter((condition) => condition && condition?.includes('/'))
      .map((condition) => {
        const [propertyId, optionId, limit] = condition.split('/');
        const propertyValue = form.value?.properties?.[propertyId];
        const eventProperty = props.event?.guestlist?.properties?.find((property) => property.id === propertyId);
        const eventOption = eventProperty?.options?.find((option) => option.id === propertyValue);

        if (eventProperty && eventOption) {
          const hasOption = Array.isArray(propertyValue) ? propertyValue.includes(optionId) : propertyValue === optionId;

          return hasOption ? Number(limit) : 0;
        } else {
          return 0;
        }
      });

    return conditionLimits.length ? Math.max(...conditionLimits) : 0;
  }

  return 0;
});
const isBeforeEndDate = computed(() => {
  if (props.preview) {
    return true;
  }

  if (!props.event?.general?.endDate) {
    return true;
  }

  return useDayjs().isBefore(useDayjs(props.event?.general?.endDate));
});

// Methods
const getConsentDescription = (consent) => {
  const attachedFile = consent[lang.value]?.attachedFile?.link;
  if (attachedFile) {
    return `<a href="${attachedFile}" target="_blank">${section.$t('xyz994')}</a>`;
  }

  return undefined;
};

const onSubmit = async() => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  const response = await useApi.post('/public/guests/register', {
    body: {
      formData: form.value,
      eventId: props.event?._id,
      formSectionId: currentInstance?.vnode?.key,
      guestId: props.guest?._id,
      pageId: props.pageId,
      lang: lang.value?.replace('_', ''),
    },
  }).catch((error) => {
    if (error?.statusCode === 400 && error?.response?._data) {
      useToast.error(error.response._data);
      emit('refresh-stats');
    } else {
      useToast.error(section.$t('abc255'));
    }
    return false;
  });
  isSubmitting.value = false;

  if (response) {
    isSubmitted.value = true;
  }
};

// Watchers
watch(() => props.guest, () => {
  if (props.guest) {
    form.value = {
      ...props.guest,
      properties: {
        ...props.guest?.properties || {},
      },
      partners: 0,
      givenConsents: props.guest?.status?.givenConsents || [],
    };

    if (props.guest?.status?.confirmation <= 0) {
      form.value = {
        ...form.value,
        partners: 0,
      };
    }

    if (props.guest?.status?.confirmation > 0) {
      form.value = {
        ...form.value,
        partners: props.guest?.status?.confirmation - 1,
      };
    }
  } else {
    form.value = {
      ...form.value,
      properties: {
        firstName: '',
        lastName: '',
        email: '',
        lang: section.lang?.value?.replace('_', ''),
      },
      givenConsents: [],
      partners: 0,
    };
  }
}, { immediate: true });
</script>

<style>
.lottie-check {
  path[fill="rgb(40,166,69)"] {
    fill: var(--section-primary-color);
  }

  path[fill="rgb(239,255,243)"] {
    fill: var(--section-primary-color);
    fill-opacity: 0.15;
  }
}
</style>
